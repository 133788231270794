/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/css/normalize.css'
import './src/css/animate.css'
import './src/css/progressbar.css'
import './src/css/owl.carousel.css'
import './src/css/typed.css'
import 'bootstrap/dist/css/bootstrap.css'
import './src/css/style.css'
import './src/css/responsive.css'
